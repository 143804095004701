<template>
  <div>
    <b-card title="TTE" class="postop">
      <b-form @submit.prevent class="px-2">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-card class="bordered-card" title="Klappenstatus">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Mitralinsuffizienz"
                        label-for="postop_tte_klappenstatus_mitralinsuff"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.postop_tte_klappenstatus_mitralinsuff
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Mitralinsuffizienz"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Mitralstenose"
                        label-for="postop_tte_klappenstatus_mitralstenose"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.postop_tte_klappenstatus_mitralstenose
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Mitralstenose"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Aorteninsuffizienz"
                        label-for="postop_tte_klappenstatus_aorteninsuff"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.postop_tte_klappenstatus_aorteninsuff
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Aorteninsuffizienz"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Aortenstenose"
                        label-for="postop_tte_klappenstatus_aortenstenose"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.postop_tte_klappenstatus_aortenstenose
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Aortenstenose"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Trikuspidalklappeninsuffizienz"
                        label-for="postop_tte_klappenstatus_trikuspidalklappeninsuff"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.postop_tte_klappenstatus_trikuspidalklappeninsuff
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Trikuspidalklappeninsuffizienz"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-card class="bordered-card" title="Rechter Ventrikel">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Gemessener Gradient über Triskuspidalklappe [mmHg]"
                        label-for="postop_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.postop_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe
                          "
                          :options="nichterhoben"
                          taggable
                          :disabled="readOnly"
                          placeholder=""
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="TAPSE [mm]"
                        label-for="postop_tte_rechter_ventrikel_tapse"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.postop_tte_rechter_ventrikel_tapse
                          "
                          :options="nichterhoben"
                          taggable
                          :disabled="readOnly"
                          placeholder="TAPSE [mm]"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card title="Komplikationen" class="postop">
      <b-form @submit.prevent class="px-2">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Herzinfarkt"
                  label-for="postop_komplikationen_herzinfarkt"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_komplikationen_herzinfarkt"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Re-Ops"
                  label-for="postop_komplikationen_re_ops"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_komplikationen_re_ops"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>

              <template
                v-if="patientInfo.postop_komplikationen_re_ops === 'ja'"
              >
                <b-col cols="12" key="postkompreopsursache">
                  <b-form-group
                    label="Re-Ops Ursache"
                    label-for="postop_komplikationen_re_ops_ursache"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.postop_komplikationen_re_ops_ursache"
                      id="postop_komplikationen_re_ops_ursache"
                      type="text"
                      placeholder=""
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </template>
              <b-col cols="12">
                <b-form-group
                  label="Nierenversagen"
                  label-for="postop_komplikationen_nierenversagen"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_komplikationen_nierenversagen"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Blutung"
                  label-for="postop_komplikationen_blutung"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_komplikationen_blutung"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Mediastinitis"
                  label-for="postop_komplikationen_mediastinitis"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_komplikationen_mediastinitis"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Zerebrales Ereignis"
                  label-for="postop_komplikationen_zerebrales_ereignis"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.postop_komplikationen_zerebrales_ereignis
                    "
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Rhythmusstörung"
                  label-for="postop_komplikationen_rhythmussstoerung"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.postop_komplikationen_rhythmussstoerung
                    "
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <template
                v-if="
                  patientInfo.postop_komplikationen_rhythmussstoerung === 'ja'
                "
              >
                <b-col cols="12" key="postkomprhythmusart">
                  <b-form-group
                    label="Art der Störung"
                    label-for="postop_komplikationen_rhythmussstoerung_art"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.postop_komplikationen_rhythmussstoerung_art
                      "
                      :options="['VHF', 'AV-Block', 'VT']"
                      multiple
                      :close-on-select="false"
                      :disabled="readOnly"
                    />
                    <!-- TODO this fails to save -->
                  </b-form-group>
                </b-col>
              </template>
              <b-col cols="12">
                <b-form-group
                  label="Dialyse"
                  label-for="postop_komplikationen_dialyse"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_komplikationen_dialyse"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Low Cardiac Ouput"
                  label-for="postop_komplikationen_low_cardiac_output"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.postop_komplikationen_low_cardiac_output
                    "
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Respiratorische Insuffizienz"
                  label-for="postop_komplikationen_resp_insuff"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_komplikationen_resp_insuff"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <template
                v-if="patientInfo.postop_komplikationen_resp_insuff === 'ja'"
              >
                <b-col cols="12" key="postkomptracheo">
                  <b-form-group
                    label="Tracheostoma"
                    label-for="postop_komplikationen_resp_insuff_tracheostoma"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.postop_komplikationen_resp_insuff_tracheostoma
                      "
                      :options="yesno"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <template
                  v-if="
                    patientInfo.postop_komplikationen_resp_insuff_tracheostoma ===
                    'ja'
                  "
                >
                  <b-col cols="12" key="postkomptracheodatum">
                    <b-form-group
                      label="Tracheostoma Datum"
                      label-for="postop_komplikationen_resp_insuff_tracheostoma_date"
                      label-cols-md="4"
                    >
                      <b-input-group class="mb-1">
                        <b-form-input
                          id="postop_komplikationen_resp_insuff_tracheostoma_date"
                          v-model="
                            patientInfo.postop_komplikationen_resp_insuff_tracheostoma_date
                          "
                          type="text"
                          placeholder="DD.MM.YYYY"
                          v-mask="dateMask"
                          autocomplete="off"
                          show-decade-nav
                          :readonly="readOnly"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="date2"
                            value-as-date
                            show-decade-nav
                            button-only
                            button-variant="outline-primary"
                            right
                            size="sm"
                            locale="de"
                            aria-controls="example-input"
                            :disabled="readOnly"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </template>
              </template>
              <b-col cols="12">
                <b-form-group
                  label="Delir"
                  label-for="postop_komplikationen_delir"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_komplikationen_delir"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Gefäßkomplikationen"
                  label-for="postop_komplikationen_gefaesskomplikationen"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.postop_komplikationen_gefaesskomplikationen
                    "
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>

              <template
                v-if="
                  patientInfo.postop_komplikationen_gefaesskomplikationen ===
                  'ja'
                "
              >
                <b-col cols="12" key="postkompgefaessart">
                  <b-form-group
                    label="Welche Gef.-Komplikationen"
                    label-for="postop_komplikationen_gefaesskomplikationen_welche"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.postop_komplikationen_gefaesskomplikationen_welche
                      "
                      id="postop_komplikationen_gefaesskomplikationen_welche"
                      type="text"
                      placeholder=""
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </template>

              <b-col cols="12"></b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card title="ICU" class="postop">
      <b-form @submit.prevent class="px-2">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="ICU Dauer"
                  label-for="postop_icu_dauer"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_icu_dauer"
                    id="postop_icu_dauer"
                    type="number"
                    placeholder="ICU Dauer"
                    :readonly="readOnly"
                  />
                  <!-- TODO add einheit -->
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Beatmungsdauer"
                  label-for="postop_icu_beatmungsdauer"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_icu_beatmungsdauer"
                    id="postop_icu_beatmungsdauer"
                    type="number"
                    placeholder="Beatmungsdauer"
                    :readonly="readOnly"
                  />
                  <!-- TODO add einheit -->
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { convertDate2Str, dateMask } from "@core/utils/utils";
import { mapState, mapActions } from "vuex";
import config from "@/config";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  timers: {
    updatePatient: config.TIMER_OPTION,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        q_a: "",
        q_b: "",
        q_c: "",
        q_d_date: "",
        q_e_date: "",
        postop_tte_klappenstatus_mitralinsuff: "",
        postop_tte_klappenstatus_mitralstenose: "",
        postop_tte_klappenstatus_aorteninsuff: "",
        postop_tte_klappenstatus_aortenstenose: "",
        postop_tte_klappenstatus_trikuspidalklappeninsuff: "",
        postop_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe:
          "",
        postop_tte_rechter_ventrikel_tapse: "",
        postop_komplikationen_herzinfarkt: "",
        postop_komplikationen_re_ops: "",
        postop_komplikationen_re_ops_ursache: "",
        postop_komplikationen_nierenversagen: "",
        postop_komplikationen_blutung: "",
        postop_komplikationen_mediastinitis: "",
        postop_komplikationen_zerebrales_ereignis: "",
        postop_komplikationen_rhythmussstoerung: "",
        postop_komplikationen_rhythmussstoerung_art: [],
        postop_komplikationen_dialyse: "",
        postop_komplikationen_low_cardiac_output: "",
        postop_komplikationen_resp_insuff: "",
        postop_komplikationen_resp_insuff_tracheostoma: "",
        postop_komplikationen_resp_insuff_tracheostoma_date: "",
        postop_komplikationen_delir: "",
        postop_komplikationen_gefaesskomplikationen: "",
        postop_komplikationen_gefaesskomplikationen_welche: "",
        postop_icu_dauer: "",
        postop_icu_beatmungsdauer: "",
      },
      hospital: null,
      hospitals: [],
      yesno: ["ja", "nein"],
      nichterhoben: ["nicht erhoben"],
      jnnichterhoben: ["ja", "nein", "nicht erhoben"],
      birth: "",
      gender: "",
      date: null,
      date2: null,
      date_q_d: null,
      date_q_e: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    date2(v, ov) {
      if (v) {
        this.patientInfo.postop_komplikationen_resp_insuff_tracheostoma_date =
          convertDate2Str(v);
      }
    },
    date_q_d(v, ov) {
      if (v) {
        this.patientInfo.q_d_date = convertDate2Str(v);
      }
    },
    date_q_e(v, ov) {
      if (v) {
        this.patientInfo.q_e_date = convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    this.fetchHospitals();
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  // beforeDestroy() {
  //   this.updatePatient()
  // },
  methods: {
    dateMask,
    ...mapActions("patient", ["updatePatientById"]),
    updatePatient() {
      if (this.readOnly) {
        return;
      }

      let activeElement = document.activeElement;
      let blockingElements = ["input", "textarea"];

      // check whether an input field is the active elemnent,
      //if it is, do not interrupt the users entries with updating
      // the patientInfo but return instead
      if (
        activeElement &&
        blockingElements.indexOf(activeElement.tagName.toLowerCase()) !== -1
      ) {
        return;
      }

      this.updatePatientById(this.patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    fetchHospitals() {
      this.$http
        .get("/hospitals/list_for_update")
        .then((res) => {
          this.hospitals = res.data.hospitals;
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while fetching hospitals."
          );
        });
    },
    handleSave() {
      this.$http
        .patch(`/patients/update/${this.$route.params.id}`, this.patientInfo)
        .then((res) => {
          this.showToast("success", "Success", "Successfully updated.");
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while saving the patient."
          );
        });
    },
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.q_a = v.q_a;
      this.patientInfo.q_b = v.q_b;
      this.patientInfo.q_c = v.q_c;
      this.patientInfo.q_d_date = v.q_d_date;
      this.patientInfo.q_e_date = v.q_e_date;
      this.patientInfo.postop_tte_klappenstatus_mitralinsuff =
        v.postop_tte_klappenstatus_mitralinsuff;
      this.patientInfo.postop_tte_klappenstatus_mitralstenose =
        v.postop_tte_klappenstatus_mitralstenose;
      this.patientInfo.postop_tte_klappenstatus_aorteninsuff =
        v.postop_tte_klappenstatus_aorteninsuff;
      this.patientInfo.postop_tte_klappenstatus_aortenstenose =
        v.postop_tte_klappenstatus_aortenstenose;
      this.patientInfo.postop_tte_klappenstatus_trikuspidalklappeninsuff =
        v.postop_tte_klappenstatus_trikuspidalklappeninsuff;
      this.patientInfo.postop_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe =
        v.postop_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe;
      this.patientInfo.postop_tte_rechter_ventrikel_tapse =
        v.postop_tte_rechter_ventrikel_tapse;
      this.patientInfo.postop_komplikationen_herzinfarkt =
        v.postop_komplikationen_herzinfarkt;
      this.patientInfo.postop_komplikationen_re_ops =
        v.postop_komplikationen_re_ops;
      this.patientInfo.postop_komplikationen_re_ops_ursache =
        v.postop_komplikationen_re_ops_ursache;
      this.patientInfo.postop_komplikationen_nierenversagen =
        v.postop_komplikationen_nierenversagen;
      this.patientInfo.postop_komplikationen_blutung =
        v.postop_komplikationen_blutung;
      this.patientInfo.postop_komplikationen_mediastinitis =
        v.postop_komplikationen_mediastinitis;
      this.patientInfo.postop_komplikationen_zerebrales_ereignis =
        v.postop_komplikationen_zerebrales_ereignis;
      this.patientInfo.postop_komplikationen_rhythmussstoerung =
        v.postop_komplikationen_rhythmussstoerung;
      this.patientInfo.postop_komplikationen_rhythmussstoerung_art =
        v.postop_komplikationen_rhythmussstoerung_art;
      this.patientInfo.postop_komplikationen_dialyse =
        v.postop_komplikationen_dialyse;
      this.patientInfo.postop_komplikationen_low_cardiac_output =
        v.postop_komplikationen_low_cardiac_output;
      this.patientInfo.postop_komplikationen_resp_insuff =
        v.postop_komplikationen_resp_insuff;
      this.patientInfo.postop_komplikationen_resp_insuff_tracheostoma =
        v.postop_komplikationen_resp_insuff_tracheostoma;

      this.patientInfo.postop_komplikationen_resp_insuff_tracheostoma_date =
        v.postop_komplikationen_resp_insuff_tracheostoma_date;

      this.patientInfo.postop_komplikationen_delir =
        v.postop_komplikationen_delir;
      this.patientInfo.postop_komplikationen_gefaesskomplikationen =
        v.postop_komplikationen_gefaesskomplikationen;
      this.patientInfo.postop_komplikationen_gefaesskomplikationen_welche =
        v.postop_komplikationen_gefaesskomplikationen_welche;

      this.patientInfo.postop_icu_dauer = v.postop_icu_dauer;
      this.patientInfo.postop_icu_beatmungsdauer = v.postop_icu_beatmungsdauer;
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.postop {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
